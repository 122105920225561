var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.anuncios == 0)?_c('div',{staticClass:"container mb-4"},[_vm._m(0)]):_vm._e(),_vm._v(" "),(_vm.anuncios > 0)?_c('div',{staticClass:"container mb-4"},[_vm._m(1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"estoque-vazio"},[_c('div',{staticClass:"box"},[_vm._m(2),_vm._v(" "),_c('p',{staticClass:"text-muted"},[_vm._v("Preencha o formulário abaixo e deixe que nossos vendedores especializados te ajude a encontrar o seu novo veículo!")]),_vm._v(" "),_c('form',{attrs:{"action":_vm.url,"method":"post"}},[_c('input',{attrs:{"type":"hidden","name":"_token"},domProps:{"value":_vm.csrf}}),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"offset-lg-12 text-center"},[_c('h4',{staticClass:"h4"},[_c('strong',[_vm._v("Nenhum veículo encontrado na busca...")])]),_vm._v(" "),_c('p',[_vm._v("Desculpe, mas não encontramos nenhum veículo com base nos filtros que você forneceu. Por favor, tente diminuir os filtros, ou se desejar, preencha o formulário abaixo para que os nossos vendedores ajudem você a encontrar um veículo!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"offset-lg-12 text-center"},[_c('h4',{staticClass:"h4"},[_c('strong',[_vm._v("Não encontramos mais veículos em nosso estoque...")])]),_vm._v(" "),_c('p',[_vm._v("Desculpe, mas não encontramos mais veículos em nosso estoque de seminovos. Se desejar, preencha o formulário abaixo para que nossos vendedores te ajudem a encontrar um veículo, ou te avise quando um veículo estiver disponível.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-header"},[_c('h1',{staticClass:"h4"},[_vm._v("Precisando de "),_c('strong',{staticClass:"text-primary"},[_vm._v("ajuda")]),_vm._v("? Nós ajudamos você a encontrar seu veículo!")]),_vm._v(" "),_c('div',{staticClass:"box-header-border"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":"nome"}},[_vm._v("Nome completo")]),_vm._v(" "),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"nome","id":"nome","required":""}}),_vm._v(" "),_c('p',{staticClass:"form-text"},[_vm._v("Por favor, informe o seu nome para te identificarmos.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-lg-7"},[_c('label',{attrs:{"for":"email"}},[_vm._v("E-mail")]),_vm._v(" "),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"email","id":"email","required":""}}),_vm._v(" "),_c('p',{staticClass:"form-text"},[_vm._v("Informe um e-mail para entrarmos em contato.")])]),_vm._v(" "),_c('div',{staticClass:"col-lg-5"},[_c('label',{attrs:{"for":"telefone"}},[_vm._v("Telefone")]),_vm._v(" "),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"telefone","id":"telefone"}}),_vm._v(" "),_c('p',{staticClass:"form-text"},[_vm._v("Informe um telefone de contato (opcional).")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row form-group"},[_c('div',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":"mensagem"}},[_vm._v("Mensagem")]),_vm._v(" "),_c('textarea',{staticClass:"form-control",attrs:{"name":"mensagem","id":"mensagem","cols":"30","rows":"5","required":""}}),_vm._v(" "),_c('p',{staticClass:"form-text"},[_vm._v("Informe o veículo que você está procurando para que um dos nossos vendedores auxiliem você em sua busca.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-footer"},[_c('button',{staticClass:"btn btn-lg btn-primary",attrs:{"type":"submit"}},[_vm._v("Enviar mensagem")])])
}]

export { render, staticRenderFns }