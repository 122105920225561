<template>
    <div class="estoque-component">
        <div v-bind:class="{ destaque : anuncio.em_destaque }" class="estoque-container">

            <!-- Imagem do anúncio -->
            <div class="estoque-imagem">
                <a :href="anuncio.url"><img :src="anuncio.capa" alt="" class="img-fluid"></a>
            </div>

            <!-- Opções do anúncio -->
            <div class="estoque-opcao">
                <div class="estoque-opcao-acoes">
                    <a :href="url + '/anuncio/favoritar/' + anuncio.id" class="btn shadow"><i class="fas fa-star fa-fw"></i></a>
                    <a :href="url + '/comparar/' + anuncio.id" class="btn shadow"><i class="fas fa-exchange-alt fa-fw"></i></a>
                </div>
            </div>

            <!-- Título do anúncio -->
            <div class="estoque-titulo">
                <div class="estoque-titulo-marca-imagem mr-4">
                    <div class="estoque-titulo-marca">
                        <img :src="anuncio.marca_logotipo" alt="" class="img-fluid">
                    </div>
                </div>
                <div class="estoque-titulo-marca-texto">
                    <h2 class="h2">{{ anuncio.marca }}</h2>
                    <h3 class="h3">{{ anuncio.modelo }}</h3>
                </div>
            </div>

            <!-- Detalhes do anúncio -->
            <div class="estoque-detalhe">
                <div class="estoque-valor">
                    <span class="estoque-valor-texto"><small>R$</small> {{ anuncio.valor }}</span>
                </div>
                <span class="estoque-detalhe-parametro">Ano<br>{{ anuncio.ano}}</span>
                <span class="estoque-detalhe-parametro">KM<br>{{ anuncio.quilometragem }}</span>
            </div>

            <!-- Botão de ação -->
            <div class="estoque-botao">
                <div class="estoque-botao-btn">
                    <a :href="anuncio.url" target="_blank">
                        <i class="fas fa-arrow-right mr-3"></i> Quero este veículo!
                    </a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: ['anuncio'], 
        data: function() {
            return {
                url: $('meta[name="app-url"]').attr('content'), 
            }
        }
    }
</script>
