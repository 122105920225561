<template>

    <div>
        <div class="container mb-4" v-if="anuncios == 0">
            <div class="row">
                <div class="offset-lg-12 text-center">
                    <h4 class="h4"><strong>Nenhum veículo encontrado na busca...</strong></h4>
                    <p>Desculpe, mas não encontramos nenhum veículo com base nos filtros que você forneceu. Por favor, tente diminuir os filtros, ou se desejar, preencha o formulário abaixo para que os nossos vendedores ajudem você a encontrar um veículo!</p>
                </div>
            </div>
        </div>
        <div class="container mb-4" v-if="anuncios > 0">
            <div class="row">
                <div class="offset-lg-12 text-center">
                    <h4 class="h4"><strong>Não encontramos mais veículos em nosso estoque...</strong></h4>
                    <p>Desculpe, mas não encontramos mais veículos em nosso estoque de seminovos. Se desejar, preencha o formulário abaixo para que nossos vendedores te ajudem a encontrar um veículo, ou te avise quando um veículo estiver disponível.</p>
                </div>
            </div>
        </div>

        <div class="estoque-vazio">
            <div class="box">
                <div class="box-header">
                    <h1 class="h4">Precisando de <strong class="text-primary">ajuda</strong>? Nós ajudamos você a encontrar seu veículo!</h1>
                    <div class="box-header-border"></div>
                </div>
                <p class="text-muted">Preencha o formulário abaixo e deixe que nossos vendedores especializados te ajude a encontrar o seu novo veículo!</p>
                <form :action="url" method="post">
                    <input type="hidden" name="_token" v-bind:value="csrf">
                    <div class="row form-group">
                        <div class="col-lg-12">
                            <label for="nome">Nome completo</label>
                            <input type="text" name="nome" class="form-control" id="nome" required>
                            <p class="form-text">Por favor, informe o seu nome para te identificarmos.</p>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-lg-7">
                            <label for="email">E-mail</label>
                            <input type="text" name="email" class="form-control" id="email" required>
                            <p class="form-text">Informe um e-mail para entrarmos em contato.</p>
                        </div>
                        <div class="col-lg-5">
                            <label for="telefone">Telefone</label>
                            <input type="text" name="telefone" class="form-control" id="telefone">
                            <p class="form-text">Informe um telefone de contato (opcional).</p>
                        </div>
                    </div>
                    <div class="row form-group">
                        <div class="col-lg-12">
                            <label for="mensagem">Mensagem</label>
                            <textarea name="mensagem" id="mensagem" cols="30" rows="5" class="form-control" required></textarea>
                            <p class="form-text">Informe o veículo que você está procurando para que um dos nossos vendedores auxiliem você em sua busca.</p>
                        </div>
                    </div>

                    <div class="box-footer">
                        <button type="submit" class="btn btn-lg btn-primary">Enviar mensagem</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['csrf', 'anuncios'], 
        data() {
            return {
                url: window.location.origin + '/estoque/ajude-a-encontrar'
            }
        }, 
        mounted() {
            console.log(this.anuncios);
            console.log(this.url);
        }
    }
</script>
