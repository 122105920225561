<template>
    <div class="estoque-component">

        <!-- Sidebar -->
        <div class="estoque-sidebar">
            <nav class="navbar-expand-lg">

                <!-- Toggler da sidebar -->
                <button class="navbar-toggler" type="button" v-on:click.prevent="showFilters = ! showFilters">
                    <a class="btn btn-sm btn-outline-primary" href="#"><i class="fas fa-filter fa-fw mr-2"></i> Filtrar</a>
                </button>
                
                <!-- Collapse da sidebar -->
                <div v-bind:class="{ collapse: ! showFilters }" id="navbarFiltro" class="navbar-collapse">
                    <form action="#" class="container-fluid">
                        <div class="form-group">
                            <label for="s">Pesquisar no estoque</label>
                            <input type="text" name="s" id="s" class="form-control form-control-lg" v-model="s" placeholder="Pesquisar marca, modelo, ano..." autocomplete="off">
                            <p class="form-text">Pesquise pela marca, nome do veículo, modelo ou ano.</p>
                        </div>

                        <div class="form-group">
                            <label for="ano">Filtrar por ano</label>
                            <div class="input-group">
                                <input type="text" name="ano_minimo" class="form-control" placeholder="De" v-model="ano_minimo" autocomplete="off">
                                <input type="text" name="ano_maximo" class="form-control" placeholder="Até" v-model="ano_maximo" autocomplete="off">
                            </div>
                            <p class="form-text">Filtre os anúncios com base no ano mínimo e máximo dos modelos.</p>
                        </div>

                        <div class="form-group">
                            <label for="valor">Filtrar por valor</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">R$</span>
                                </div>
                                <input type="text" name="valor_minimo" class="form-control" placeholder="De" v-model="valor_minimo" autocomplete="off">
                                <input type="text" name="valor_maximo" class="form-control" placeholder="Até" v-model="valor_maximo" autocomplete="off">
                            </div>
                            <p class="form-text">Filtre os anúncios com base no valor inicial e final.</p>
                        </div>

                        <div class="form-group">
                            <label for="quilometragem">Filtrar por quilômetragem</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">KM</span>
                                </div>
                                <input type="text" name="quilometragem_minima" class="form-control" placeholder="De" v-model="quilometragem_minima" autocomplete="off">
                                <input type="text" name="quilometragem_maxima" class="form-control" placeholder="Até" v-model="quilometragem_maxima" autocomplete="off">
                            </div>
                            <p class="form-text">Filtre os anúncios com base no quilômetragem dos veículos anúnciados.</p>
                        </div>

                        <div class="form-group">
                            <label for="itens">Escolher opcionais</label>
                            <p class="form-text">Marque os opcionais que deseja filtrar em suas buscas, ou deixe todos desmarcados para não considerar.</p>
                            <div class="custom-control custom-checkbox" v-for="item in itens" :key="item.id">
                                <input type="checkbox" v-model="selectedItens" :value="item.id" :id="'item-checkbox-' + item.id" class="custom-control-input">
                                <label :for="'item-checkbox-' + item.id" class="custom-control-label">{{ item.titulo }}</label>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="cores">Filtrar cores</label>
                            <p class="form-text">Marque as cores que você prefere ou deixe desmarcado para considerar todas.</p>
                            <div class="custom-control custom-checkbox" v-for="cor in cores" :key="cor.id">
                                <input type="checkbox" v-model="selectedCores" :value="cor.id" :id="'cor-checkbox-' + cor.id" class="custom-control-input">
                                <label :for="'cor-checkbox-' + cor.id" class="custom-control-label">{{ cor.titulo }}</label>
                            </div>
                        </div>

                        <!--<div class="form-group">
                            <label for="transmissao">Transmissão</label>
                            <select name="transmissao" id="transmissao" class="form-control" v-model="transmissao">
                                <option value="">Qualquer transmissão</option>
                                <option value="manu">Somente manual</option>
                                <option value="auto">Somente automático</option>
                                <option value="autm">Somente automatizado</option>
                                <option value="cvt">Somente automático CVT</option>
                            </select>
                            <p class="form-text">Informe caso deseje filtrar um tipo específico de transmissão.</p>
                        </div>-->

                        <div class="estoque-sidebar-submit" style="z-index: 5;">
                            <button v-on:click.prevent="search" class="btn btn-lg btn-primary app-btn shadow-lg" type="submit"><i class="fas fa-search fa-fw mr-3"></i> Buscar veículos!</button>
                        </div>
                    </form>
                </div>
            </nav>
        </div>

        <!-- Exibição dos anúncios -->
	    <div class="estoque-main mt-4">
	    	<div class="container-fluid">
                <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="limit" class="row row-eq-height">
                    <div class="col-sm-6 col-lg-4 mb-3" v-for="anuncio in anuncios" v-bind:key="anuncio.id">
                        <estoque-item :anuncio="anuncio"></estoque-item>
                    </div>
                </div>
            </div>
        </div>

        <!-- Request loading -->
        <div class="estoque-loading" v-if="busy"></div>

        <!-- Informativo de registro não encontrado -->
        <div class="container">
            <div class="row">
                <div class="offset-lg-3 col-lg-6 mb-5">
                    <estoque-vazio v-if="empty" :csrf="csrf" :anuncios="Object.keys(anuncios).length"></estoque-vazio>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    // Importing the Infinite Scroll library.
    import infiniteScroll from 'vue-infinite-scroll';

    // Get the URL params
    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    export default {
        directives: {infiniteScroll}, 
        data: function() {
            return {
                apiEndpoint: $('meta[name="api-url"]').attr('content') + '/api', 
                anuncios: [],
                itens: [], 
                cores: [], 
                selectedItens: [], 
                selectedCores: [],
                limit: 18, 
                page: 1, 
                empty: false, 
                busy: false, 
                total: 0, 
                csrf: $('meta[name="csrf-token"]').attr('content'), 

                // Filtros
                showFilters: false, 

                // Search parameters
                s: getUrlParameter('s'),
                cor: getUrlParameter('cor'), 
                valor_minimo: getUrlParameter('valor_minimo'), 
                valor_maximo: getUrlParameter('valor_maximo'), 
                ano_minimo: getUrlParameter('ano_minimo'), 
                ano_maximo: getUrlParameter('ano_maximo'), 
                quilometragem_minima: getUrlParameter('quilometragem_minima'), 
                quilometragem_maxima: getUrlParameter('quilometragem_maxima'), 
                transmissao: getUrlParameter('transmissao'), 
            }
        }, 
        methods: {
            loadMore() {
                
                if ( ! this.empty) {
                    // Sets the application state to "busy".
                    this.busy = true;

                    // Sends a get request to the API endpoint.
                    axios.get(this.apiEndpoint + '/anuncios', {
                        params: {
                            page: this.page, 
                            limit: this.limit, 
                            s: this.s, 
                            ano_minimo: this.ano_minimo, 
                            ano_maximo: this.ano_maximo, 
                            valor_minimo: this.valor_minimo, 
                            valor_maximo: this.valor_maximo, 
                            quilometragem_minima: this.quilometragem_minima, 
                            quilometragem_maxima: this.quilometragem_maxima, 
                            itens: this.selectedItens.join(), 
                            cores: this.selectedCores.join(), 
                            transmissao: this.transmissao
                        }
                    }).then(response => {

                        const append = response.data.data;
                        this.anuncios = this.anuncios.concat(append);
                        
                        // Verifica se existem mais registros para 
                        // serem retornados para a view, evitando várias 
                        // requests desnecessárias.
                        if(append.length) {
                            this.page++;
                        } else {
                            this.empty = true;
                        }

                        this.busy = false;
                    });
                }
            }, 
            search() {
                this.anuncios = [];
                this.page = 1;
                this.empty = false;
                this.showFilters = false;

                this.loadMore();
            }, 
            getItems() {
                axios.get(this.apiEndpoint + '/itens').then(response => {
                    this.itens = response.data.data;
                });
            }, 
            getCores() {
                axios.get(this.apiEndpoint + '/cores').then(response => {
                    this.cores = response.data.data
                })
            }
        },
	
        created() {
            // Load the items when the instance was created.
            this.getItems();
            this.getCores();
        }
    }
</script>